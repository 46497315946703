import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

/**
 * Button to move the user to an unauthenticated state.
 */
function LogoutButton() {
  const { logout } = useAuth0();

  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => logout({
        logoutParams: {
          // must be an Allowed Logout URL configured in the Auth0 app
          returnTo: window.location.origin,
        },
      })}
    >
      Sign Out
    </Button>
  );
}

export default LogoutButton;
