import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from './features/Footer/Footer';
import Header from './features/Header/Header';

function Root() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={0}>
        <Grid xs={12}>
          <Header />
        </Grid>
        <Grid xs={12}>
          <Outlet />
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Root;
