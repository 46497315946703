import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import ModuleGallery from './ModuleGallery';
import CallToActionButtonGroup from '../../components/CallToActionButtonGroup';
import SectionContainer from '../../components/SectionContainer';
import content from './ModulesContent';

function CallToAction() {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={8} alignItems="center">
      <Typography
        variant="h2"
        fontWeight={700}
        color={theme.palette.primary.dark}
      >
        { content.headline}
      </Typography>
      <Typography
        variant="h4"
        fontWeight={400}
        color={theme.palette.primary.dark}
      >
        { content.subHeadline}
      </Typography>
      <CallToActionButtonGroup justifyContent="center" variant="green" />
    </Stack>
  );
}

function Modules() {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ backgroundColor: theme.palette.primary.contrastText }}>
      <Stack spacing={8}>
        <CallToAction />
        <ModuleGallery />
      </Stack>
    </SectionContainer>
  );
}

export default Modules;
