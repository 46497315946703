import ReactLogo from '../../assets/reactjs-icon.svg';
import PostgresLogo from '../../assets/postgres-logo.svg';
import LambdaLogo from '../../assets/AWS-lambda-logo.svg';

export default [
  {
    img: ReactLogo,
    title: 'Frontend Module',
    body: "Marlin's frontend module is built with React and deploys to AWS CloudFront and S3. The module comes with webpack and babel pre-installed, plus a few utility scripts.",
    docs: 'https://marlincode.notion.site/Jumpstart-Your-Frontend-3788900c2f2843a29da725fbbb3d6aa1',
  },
  {
    img: LambdaLogo,
    title: 'API Module',
    body: "Marlin's API module delivers backend via API Gateway and AWS lambda. All you need to do is write the processing logic, the module does the rest.",
    docs: 'https://marlincode.notion.site/Jumpstart-Your-API-3f51845ff96d46c78ceefef89a5b71c8',
  },
  {
    img: PostgresLogo,
    title: 'Database Module',
    body: "Marlin's database module deploys a preconfigured postgres instance in AWS RDS. You can modify the database with standard tooling such as pgAdmin, and connect it with your API module.",
    docs: 'https://marlincode.notion.site/Jumpstart-Your-Database-81e33d2edbfd4d1ab5a3bfeed0e3b648',
  },
];
