import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import Root from './Root';
import Terms from './features/Terms/Terms';
import Privacy from './features/Privacy/Privacy';
import theme from './theme';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <App />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
      {
        path: '/privacy',
        element: <Privacy />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain="marlincode.us.auth0.com"
        clientId="cXPKD7atitxYYbjtceRHrJRxYhUaWOiE"
        authorizationParams={{
          // must be an Allowed Callback URL configured in the Auth0 app.
          redirect_uri: window.location.origin,
        }}
      >
        <RouterProvider router={router} />
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
);
