import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://6eu673fwc5.execute-api.us-east-1.amazonaws.com/Prod',
  timeout: 1000,
});

export default function registerUser(name: string, email: string): void {
  const payload = {
    name,
    email,
  };
  instance.post('/users', payload);
}
