import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

/**
 * Used to redirect a user to your Universal Login page hosted by Auth0
 */
function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => loginWithRedirect()}
    >
      Sign In
    </Button>
  );
}

export default LoginButton;
