import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const getPadding = (theme: Theme) => {
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  return isLarge ? '160 96' : '80 24';
};
const SectionContainer = styled(Box, {
  shouldForwardProp: () => true,
})(({ theme }) => ({
  flexGrow: 1,
  padding: getPadding(theme),
}));

export default SectionContainer;
