import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import registerUser from '../../apis/notion/Users';

function TryTodayDialog({ open, onClose }: { open: boolean, onClose: () => void }) {
  const dialogText = "Hello! We're so excited that you want to try Marlin. You've got two options. You can share your name and email, and we'll reach out to set up a 1:1 chat to get you started (free of course!), or you can skip straight to our docs and bootstrap yourself.";
  const nameRef = React.useRef('');
  const emailRef = React.useRef('');
  const start = React.useCallback(() => {
    if (nameRef?.current && emailRef?.current) {
      registerUser(nameRef.current, emailRef.current);
    }
  }, []);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Welcome!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { dialogText }
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          inputRef={nameRef}
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          id="emailAddress"
          label="Email Address"
          type="email"
          fullWidth
          inputRef={emailRef}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button href="https://marlincode.notion.site/">Skip to docs</Button>
        <Button href="https://marlincode.notion.site/" onClick={start}>Start</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TryTodayDialog;
